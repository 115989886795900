import { useRouter } from 'next/router';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

export default function Footer({ menu }) {
  const { asPath } = useRouter();

  return (
    <footer className="bg-secondary py-10 md:py-20">
      <div className="mx-auto mb-10 max-w-4xl px-6 sm:mb-10 sm:px-6 lg:mb-20 lg:px-8">
        <div className="flex-none gap-8 lg:flex">
          <div className="mb-14 basis-1/2 lg:mb-0">
            <div className="grid grid-cols-1 text-base-m font-light text-tt-white lg:text-base-d">
              <p className="mb-0">
                <FormattedMessage
                  defaultMessage={`TAMINA THERME AG`}
                  id="f2xQyO"
                />
              </p>
              <p className="mb-0">
                <FormattedMessage
                  defaultMessage={`Hans Albrecht-Strasse`}
                  id="P011Uk"
                />
              </p>
              <p className="mb-0">
                <FormattedMessage
                  defaultMessage={`7310 Bad Ragaz`}
                  id="NiST6t"
                />
              </p>
              <p className="">
                <FormattedMessage defaultMessage={`Schweiz`} id="+rMYMm" />
              </p>
              <p className="">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/maps/place/Tamina+Therme+Bad+Ragaz/@46.9993522,9.5021404,17z/data=!3m1!4b1!4m5!3m4!1s0x4784cb8c1b0f9905:0xb13b9c68eef2dd14!8m2!3d46.9993486!4d9.5043291"
                >
                  <FormattedMessage defaultMessage={`Anfahrt`} id="mvxRx3" />
                </a>
              </p>
              <p className="mb-0">
                <FormattedMessage
                  defaultMessage={`Tel. +41 81 303 27 40`}
                  id="yqNQAP"
                />
              </p>
              <p className="mb-0">
                <FormattedMessage
                  defaultMessage={`info@taminatherme.ch`}
                  id="RkWEH7"
                />
              </p>
            </div>
          </div>

          <div className="basis-1/2">
            <div className="grid grid-cols-1 text-base-m font-light uppercase text-tt-white lg:text-base-d">
              <ul className={`w-full lg:w-auto lg:items-center`}>
                {menu?.map((link) => (
                  <li key={link.id} className={`my-1`}>
                    <div>
                      <Link
                        href={link?.url ?? ''}
                        passHref
                        className={`block lg:mt-0 lg:flex ${
                          link.options?.attributes?.class &&
                          link.options.attributes.class[0]
                        }
                      ${asPath === link.url && 'opacity-50'}`}
                        target={
                          link.options?.attributes?.target &&
                          link.options.attributes.target[0]
                        }
                        legacyBehavior
                      >
                        {link.title}
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
