import { DrupalJsonApiParams } from 'drupal-jsonapi-params';
import Jsona from 'jsona';
import { getSession, signOut } from 'next-auth/react';
import { downloadFile } from './files';

const url = process.env.NEXT_PUBLIC_DRUPAL_BASE_URL;

export function getCartToken(): string {
  const clientSideToken =
    localStorage.getItem('cartToken') !== null
      ? JSON.parse(localStorage.getItem('cartToken'))
      : Math.random().toString(36).substring(2);
  localStorage.setItem('cartToken', JSON.stringify(clientSideToken));
  return clientSideToken;
}

// Prepares the headers with authentication tokens for the commerce_api calls.
export async function getCommerceApiHeaders(): Promise<Headers> {
  const headers = new Headers();
  // TODO: Handle expired refreshToken
  const session = await getSession();
  const accessToken = session?.accessToken;
  if (session?.error === 'RefreshAccessTokenError') {
    await signOut({
      callbackUrl: `/user/login?callbackUrl=${window.location.pathname}&error=SessionExpired`,
    });
  }
  // Prioritize user-authorization-token over commerce-cart-token.
  if (accessToken) {
    headers.append('Authorization', `Bearer ${accessToken}`);
  } else {
    headers.append('Commerce-Cart-Token', getCartToken());
  }
  headers.append('Content-Type', 'application/vnd.api+json');
  return headers;
}

// check if bookings are valid and return the removed items
export async function validateBookings(): Promise<Array<string>> {
  const headers = await getCommerceApiHeaders();
  const response = await fetch(`${url}/api/tac/validate-bookings`, {
    method: 'PATCH',
    headers: headers,
  });
  const data = await response.json();

  if (!response.ok) {
    console.log('Check cart items response status: ' + response.status);
    console.log('Check cart items response: ', data);
  }
  return data;
}

// Returns the cart associated with the cart token.
export async function getCart(langCode: string) {
  const headers = await getCommerceApiHeaders();

  // TODO add the language prefix dynamically
  const response = await fetch(
    `${url}/${langCode}/jsonapi/carts?include=order_items`,
    {
      method: 'GET',
      headers: headers,
    }
  );
  if (!response.ok) {
    console.log('Get cart response status: ' + response.status);
    console.log('Get cart response: ', await response.json());
    return false;
  } else {
    const data = await response.json();
    // Set cart flag when there is data
    if (data?.data[0]?.relationships?.order_items?.data?.length > 0) {
      setCartFlag();
    } else {
      removeCartFlag();
    }
    return data;
  }
}

// Adds multiple product to cart based on its id and type.
export async function addCartItems(items: Array<any>, langCode: string) {
  const headers = await getCommerceApiHeaders();

  const requestData = {
    data: items
      .filter((item) => item.quantity)
      .map((item) => {
        return {
          type: item.type,
          id: item.id,
          meta: {
            quantity: item.quantity,
            ...(item?.meta?.fields && { fields: item?.meta?.fields }),
            // Combine defines if orderitems should be merged in the order.
            ...(item?.meta?.combine == false && {
              combine: false,
            }),
          },
        };
      }),
  };
  const response = await fetch(`${url}/${langCode}/jsonapi/cart/add`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(requestData),
  });
  if (!response.ok) {
    console.log('Add cart items response status: ' + response.status);
    console.log('Add cart items response: ', await response.json());
    return false;
  } else {
    setCartFlag();
    return await response.json();
  }
}

// Changes a product in a cart based on its id and type.
export async function changeCartItem(
  type: string,
  id: string,
  quantity: number,
  cartId: string,
  langCode: string
) {
  const header = await getCommerceApiHeaders();
  var requestData = {
    data: {
      type: type,
      id: id,
      attributes: {
        quantity: quantity,
      },
    },
  };
  const response = await fetch(
    `${url}/${langCode}/jsonapi/carts/${cartId}/items/${id}`,
    {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(requestData),
    }
  );
  if (!response.ok) {
    console.log('Change cart item response status: ' + response.status);
    console.log('Change cart item response: ', await response.json());
    return false;
  } else {
    return await response.json();
  }
}

// Changes the price of a flexible voucher in a cart based on its id and type.
export async function changeFlexibleVoucherPrice(
  type: string,
  id: string,
  price: number,
  cartId: string,
  langCode: string
) {
  const header = await getCommerceApiHeaders();
  var requestData = {
    data: {
      type: type,
      id: id,
      attributes: {
        overridden_unit_price: true,
        unit_price: {
          number: price,
          currency_code: 'CHF',
        },
      },
    },
  };
  const response = await fetch(
    `${url}/${langCode}/jsonapi/carts/${cartId}/items/${id}`,
    {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(requestData),
    }
  );
  if (!response.ok) {
    console.log(
      'Change flexible voucher price response status: ' + response.status
    );
    console.log(
      'Change flexible voucher price response: ',
      await response.json()
    );
    return false;
  } else {
    return await response.json();
  }
}

// Delete a product in the cart based on its id and type.
export async function deleteCartItem(
  type: string,
  id: string,
  cartId: string,
  langCode: string
) {
  const header = await getCommerceApiHeaders();
  var requestData = {
    data: [
      {
        type: type,
        id: id,
      },
    ],
  };
  const response = await fetch(
    `${url}/${langCode}/jsonapi/carts/${cartId}/items`,
    {
      method: 'DELETE',
      headers: header,
      body: JSON.stringify(requestData),
    }
  );

  if (!response.ok) {
    console.log('Delete cart item response status: ' + response.status);
    console.log('Delete cart item response: ', await response.json());
    return false;
  } else {
    // The drupal endpoint only returns an ok response but no json therefore we return true.
    return true;
  }
}

// TODO: Remove hardcoding the shipping methods ids.
export enum ShippingMethods {
  'Gratis Postversand' = '1--default',
  'Print@Home' = '2--default',
  'Postversand' = '3--default',
}

// Apply one of the two shipping methods for vouchers
export async function changeShippingMethod(
  orderId: string,
  orderType: string,
  shippingMethodId: ShippingMethods,
  langCode: string
) {
  const header = await getCommerceApiHeaders();
  var requestData = {
    data: {
      type: orderType,
      id: orderId,
      attributes: {
        shipping_method: shippingMethodId,
      },
    },
  };
  const response = await fetch(
    `${url}/${langCode}/jsonapi/checkout/${orderId}`,
    {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(requestData),
    }
  );
  if (!response.ok) {
    return false;
  } else {
    console.log('Change shipping method response status: ' + response.status);
    console.log('Change shipping method response: ', await response.json());
    return false;
  }
}

export function createCartObject(response) {
  const cart = { order: response.data[0], orderItems: response.included };
  return cart;
}

export type Address = {
  address: {
    langcode: string;
    country_code: string;
    locality: string;
    postal_code: string;
    address_line1: string;
    given_name: string;
    family_name: string;
  };
  field_email: string;
  field_date_of_birth: string;
  field_phone_number: string;
  field_salutation: string;
  field_title: string;
  email: string;
};

// Adjust the shipping or invoice address on an order.
export async function changeAddresses(
  orderId,
  billingAddress: Address,
  shippingAddress: Address,
  langCode: string
) {
  const header = await getCommerceApiHeaders();

  // Set defaults.
  if (billingAddress?.address) {
    billingAddress.address.langcode = 'de';
  }
  if (shippingAddress?.address) {
    shippingAddress.address.langcode = 'de';
    shippingAddress.address.country_code = 'CH';
  }

  var requestData = {
    data: {
      type: 'order--default',
      id: orderId,
      attributes: {
        ...(billingAddress && {
          billing_information: {
            metatag: [],
            ...billingAddress,
            field_address_type: 'HOME',
            tax_number: {
              type: null,
              value: null,
            },
          },
        }),
        ...(shippingAddress && {
          shipping_information: {
            metatag: [],
            ...shippingAddress,
            field_address_type: 'HOME',
            tax_number: {
              type: null,
              value: null,
            },
          },
        }),
      },
    },
  };
  const response = await fetch(
    `${url}/${langCode}/jsonapi/checkout/${orderId}`,
    {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(requestData),
    }
  );
  if (!response.ok) {
    console.log('Change addresses response status: ' + response.status);
    console.log('Change addresses response: ', await response.json());
    return false;
  } else {
    return await response.json();
  }
}

// Set cart flag
export function setCartFlag() {
  return localStorage.setItem('cartItemsFlag', JSON.stringify(true));
}

// Remove cart flag
export function removeCartFlag() {
  return localStorage.setItem('cartItemsFlag', JSON.stringify(false));
}

// Get cart flag
export function getCartFlag() {
  const value = JSON.parse(localStorage.getItem('cartItemsFlag'));
  return value;
}

// Sum up the price of all adjustments
export function sumPriceAdjustments(adjustments) {
  return (
    parseFloat(
      adjustments?.reduce(
        (accumulator, current) => accumulator + current.amount.number,
        0
      )
    ) || 0
  );
}

// Set the default shipping method
export async function setDefaultShippingMethod(
  cartObject,
  refreshCart: Function,
  locale: string
) {
  // Always set the shipping method to Postversand if the cart contains retail products.
  if (hasProductType('retail-products', cartObject)) {
    if (
      cartObject?.order?.attributes?.shipping_method !==
        ShippingMethods['Postversand'] &&
      cartObject?.order?.attributes?.shipping_method !==
        ShippingMethods['Gratis Postversand']
    ) {
      const defaultShippingMethod =
        getOrderSubtotal(cartObject?.order) < 150
          ? ShippingMethods['Postversand']
          : ShippingMethods['Gratis Postversand'];
      await changeShippingMethod(
        cartObject?.order?.id,
        cartObject?.order?.type,
        defaultShippingMethod,
        locale
      );
      refreshCart(locale);
    }
    return;
  }

  // Only set the shipping method to Print@Home if the cart contains vouchers and no shipping method is set yet.
  if (hasProductType('voucher', cartObject)) {
    if (!cartObject?.order?.attributes?.shipping_method) {
      await changeShippingMethod(
        cartObject?.order?.id,
        cartObject?.order?.type,
        ShippingMethods['Print@Home'],
        locale
      );
      refreshCart(locale);
    }
    return;
  }
}

// Check if the cart contains a product of a specific type
function hasProductType(productType: string, cartObject) {
  return cartObject?.orderItems
    ?.filter((item) => item.type === 'order-item--default')
    .some(
      (item) =>
        item?.relationships?.purchased_entity?.data?.type ===
        `product-variation--${productType}`
    );
}

export const getOrderSubtotal = (order) => {
  return parseInt(order?.attributes?.order_total?.subtotal?.number);
};

// Load all orders from user.
export async function getOrders(langCode: string) {
  const headers = await getCommerceApiHeaders();
  const apiParams = new DrupalJsonApiParams();

  apiParams.addFilter('cart', 'false');
  apiParams.addInclude(['order_items']);
  apiParams.addInclude(['order_items.purchased_entity']);
  apiParams.addInclude(['field_reservation_pdf']);
  apiParams.addInclude(['field_receipt_image']);
  apiParams.addInclude(['field_voucher_info.field_voucher_media']);
  apiParams.addFields('order--default', [
    'order_total',
    'order_items',
    'field_invoice_date',
    'field_invoice_number',
    'field_reservation_booking_id',
    'order_number',
    'completed',
    'field_receipt_image',
    'field_reservation_pdf',
    'field_voucher_info',
  ]);
  apiParams.addFields('order-item--default', [
    'purchased_entity',
    'quantity',
    'total_price',
    'field_start_date',
    'field_end_date',
    'created',
    'title',
    'field_reservation_start_dates',
    'field_reservation_end_dates',
    'field_canceled',
    'cancelable',
  ]);
  apiParams.addFields('product-variation--voucher', ['title']);
  apiParams.addFields('product-variation--retail-products', ['title']);
  apiParams.addFields('product-variation--ticket', ['title']);
  apiParams.addFields('product-variation--package', ['title']);
  apiParams.addFields('file--document', ['uri']);
  apiParams.addSort('created', 'DESC');
  const queryString = apiParams.getQueryString({ encode: false });

  const response = await fetch(
    `${url}/${langCode}/jsonapi/orders/default/?${queryString}`,
    {
      method: 'GET',
      headers: headers,
    }
  );
  if (!response.ok) {
    console.log('Get cart response status: ' + response.status);
    console.log('Get cart response: ', await response.json());
    return false;
  } else {
    const dataFormatter = new Jsona();
    const data = await response.json();
    const formattedData = dataFormatter.deserialize(data);
    const formattedArrayData = [].concat(formattedData);
    return formattedArrayData;
  }
}
