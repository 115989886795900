import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { useProductsInstantSearch } from '../../../hooks/search/useProductsSearch';
import { useContentInstantSearch } from '../../../hooks/search/useContentSearch';

import ProductLiveResult from '../SearchResults/Product/ProductLiveResult/ProductLiveResult';
import ContentLiveResult from '../SearchResults/Content/ContentLiveResult/ContentLiveResult';
import SearchIcon from '../../../public/images/search-icon-positiv.svg';
import { FormattedMessage, useIntl } from 'react-intl';

interface SearchWidgetProps {
  searchIsOpen: boolean;
  setSearchIsOpen: Dispatch<SetStateAction<boolean>>;
  autocompleteIsOpen: boolean;
  setAutocompleteIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function SearchWidget({
  searchIsOpen,
  setSearchIsOpen,
  autocompleteIsOpen,
  setAutocompleteIsOpen,
}: SearchWidgetProps) {
  const router = useRouter();
  const intl = useIntl();
  const [query, setQuery] = useState('');
  const { data: products } = useProductsInstantSearch({
    page: 0,
    query: query,
  });
  const { data: content } = useContentInstantSearch({
    page: 0,
    query: query,
  });

  function handleOnClickContent(value) {
    setSearchIsOpen(value);
    setAutocompleteIsOpen(value);
  }

  function handleChange(e) {
    setQuery(e.target.value);
    setAutocompleteIsOpen(true);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setAutocompleteIsOpen(false);

    router.push({
      pathname: '/search',
      query: {
        q: e.target.keywords.value,
      },
    });
  }

  return (
    <section className="absolute left-1/2 top-28 z-40 w-full -translate-x-1/2 p-1 lg:w-1/2">
      {searchIsOpen && (
        <div>
          <form onSubmit={handleSubmit} className="relative">
            <SearchIcon
              className={`absolute left-3 top-1/2 z-10 block h-[25px] w-[26px] -translate-y-1/2 text-secondary`}
            />
            <input
              type="search"
              placeholder={intl.formatMessage({
                defaultMessage: `Suche`,
                id: 'qi41lO',
              })}
              name="keywords"
              value={query}
              onChange={handleChange}
              required
              autoFocus
              onBlur={() => {
                setSearchIsOpen(false);
              }}
              className="relative block h-[48px] w-full appearance-none rounded-[6px] border border-transparent px-3 py-2 pl-12 placeholder-gray-500 focus:border-primary focus:outline-none focus:ring-primary"
            />
          </form>

          {autocompleteIsOpen && query.length > 2 && (
            <div
              className="relative mt-4 rounded-[6px] bg-white px-10 py-7"
              // This prevents the onBlur of the search input element. Otherwise the links would not be clickable.
              onMouseDown={(event) => {
                event.preventDefault();
              }}
            >
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <section className="border-b border-b-tertiary pb-6">
                  <h3 className="mb-6 mt-0 text-base-m font-bold">
                    <FormattedMessage
                      defaultMessage="Produkte ({total})"
                      id="7ORKwt"
                      values={{ total: products?.pages[0]?.total || '0' }}
                    />
                  </h3>

                  {products?.pages[0]?.total !== 0 ? (
                    products?.pages.map((page, index) => (
                      <div key={index}>
                        <div className="flex items-center">
                          <ul className="grid gap-4">
                            {page.items?.map((entity) => (
                              <li
                                key={entity.id}
                                className="border-t border-t-tertiary pt-4"
                              >
                                <ProductLiveResult
                                  product={entity}
                                  onClick={handleOnClickContent}
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm">
                      <FormattedMessage
                        defaultMessage={`Keine Produkte gefunden`}
                        id="L8JAWV"
                      />
                    </p>
                  )}
                </section>

                <section className="border-b border-b-tertiary pb-6">
                  <h3 className="mb-6 mt-6 text-base-m font-bold lg:mt-0">
                    <FormattedMessage
                      defaultMessage="Weitere Resultate ({total})"
                      id="KoKnOh"
                      values={{ total: content?.pages[0]?.total || '0' }}
                    />
                  </h3>

                  {content?.pages[0]?.total !== 0 ? (
                    content?.pages.map((page, index) => (
                      <div key={index}>
                        <div className="flex items-center">
                          <ul className="grid gap-4">
                            {page.items?.map((entity) => (
                              <li
                                key={entity.id}
                                className="border-t border-t-tertiary pt-4"
                              >
                                <ContentLiveResult
                                  node={entity}
                                  onClick={handleOnClickContent}
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm">
                      <FormattedMessage
                        defaultMessage={`Keine Resultate gefunden`}
                        id="Xsd79G"
                      />
                    </p>
                  )}
                </section>
              </div>

              <Link
                href={`/search?q=${query}`}
                className="inline-block pt-4 uppercase"
                onClick={() => setAutocompleteIsOpen(false)}
              >
                <FormattedMessage
                  defaultMessage={`Alle Ergebnisse ansehen`}
                  id="86TL5d"
                />
              </Link>
            </div>
          )}
        </div>
      )}
    </section>
  );
}
