import Image from 'next/image';
import Link from 'next/link';

import { absoluteUrl } from 'lib/utils';

export default function ProductLiveResult({ product, onClick }) {
  function handleClick() {
    onClick(false);
  }

  return (
    <article className={product?.field_theme}>
      <Link
        onClick={handleClick}
        className="h-full flex flex-row gap-4"
        href={product?.path?.alias ?? ''}
        passHref
      >
        <div className="overflow-hidden min-w-[120px] w-[120px] h-[60px] bg-primary">
          {product?.field_image?.field_media_image ? (
            <Image
              src={absoluteUrl(
                product?.field_image?.field_media_image?.uri?.url
              )}
              alt="flex"
              className="w-full h-full object-cover"
              width={240}
              height={120}
            />
          ) : (
            <div className="overflow-hidden min-w-[120px] w-[120px] h-[60px] bg-primary"></div>
          )}
        </div>

        <h4 className="mt-0 font-bold text-base-m lg:text-base-d">
          {product.title}
        </h4>
      </Link>
    </article>
  );
}
