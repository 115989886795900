import Head from 'next/head';
import { DrupalNode } from 'next-drupal';
import { absoluteUrl } from 'lib/utils';

interface MetatagsProps {
  entity?: DrupalNode;
}

function getImageUrl(entity) {
  return absoluteUrl(
    entity?.field_teaser_image
      ? entity.field_teaser_image?.field_media_image?.uri?.url
      : entity?.field_image?.field_media_image?.uri?.url
  );
}
function getDescription(entity) {
  return (
    entity?.field_metatag_description ??
    entity?.field_teaser_text ??
    entity?.field_description?.value.replace(/(<([^>]+)>)/gi, '')
  );
}

export default function Metatags({ entity }: MetatagsProps) {
  const imageUrl = getImageUrl(entity);
  const description = getDescription(entity);
  const title = entity?.field_metatag_title ?? entity?.title;
  const index = entity?.field_indexiert ? 'index' : 'noindex';

  return (
    <Head>
      <title>{title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content={description} />
      <meta name="robots" content={`${index}, follow`} />
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={absoluteUrl(entity?.path?.alias)} />
      <meta property="og:site_name" content="Tamine Therme Bad Ragaz" />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <link rel="icon" href="/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0084be" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  );
}
