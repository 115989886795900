import { useEffect } from 'react';
import NavItem from 'components/NavItem';
import { getCartFlag } from 'lib/commerce-client';
import { useCartContext } from 'lib/cart-context';

import styles from '../../Search/SearchWidget/SearchWidget.module.css';
import SearchButton from 'components/Search/SearchButton/SearchButton';

export default function StickyShopMenu({ menu, onSearchButtonClick }) {
  const { dispatch } = useCartContext();

  // UseEffect to determine cart indicator
  useEffect(() => {
    (async () => {
      // Set flag value if localStorage value for cartFlag is true
      const flag = await getCartFlag();
      if (flag) {
        dispatch({
          type: 'setFlag',
          cartFlag: true,
        });
      }
    })();
  }, [dispatch]);

  return (
    <nav className="absolute z-20 w-full">
      <ul className={`mt-5 ml-20 flex w-auto gap-x-3 lg:mr-64 lg:justify-end`}>
        <SearchButton onClick={onSearchButtonClick} />
        {menu?.map((link) => (
          <NavItem
            key={link.id}
            className="cursor-pointer"
            showLabelsOnHover={false}
            link={link}
          />
        ))}
      </ul>
    </nav>
  );
}
