import Link from 'next/link';
import Pizol from './logos/pizol.svg';
import Casino from './logos/casino.svg';
import Flumserberg from './logos/flumserberg.svg';
import Heidiland from './logos/heidiland.svg';
import Medzentrum from './logos/medzentrum.svg';
import Grandresort from './logos/grandresort.svg';

export default function FooterPartnerLogos({}) {
  return (
    <div className="bg-white py-10 text-secondary md:py-10">
      <div className="mx-auto max-w-4xl px-6">
        <div className="grid grid-cols-2 justify-center gap-4 lg:grid-cols-6">
          <Link href="https://www.resortragaz.ch" passHref target="_blank">
            <Grandresort width={114} height={50} />
          </Link>
          <Link
            href="https://www.medizin-badragaz.ch/"
            passHref
            target="_blank"
          >
            <Medzentrum width={114} height={50} />
          </Link>
          <Link href="https://www.casinoragaz.ch/" passHref target="_blank">
            <Casino width={114} height={40} />
          </Link>
          <Link
            href="https://heidiland.com"
            className="flex items-center"
            passHref
            target="_blank"
          >
            <Heidiland width={114} height={40} />
          </Link>
          <Link
            href="https://www.flumserberg.ch"
            className="flex items-center"
            passHref
            target="_blank"
          >
            <Flumserberg width={114} height={40} />
          </Link>
          <Link
            href="https://pizol.com/"
            className="flex items-center"
            passHref
            target="_blank"
          >
            <Pizol width={114} height={30} />
          </Link>
        </div>
      </div>
    </div>
  );
}
