import { FormattedMessage } from 'react-intl';
import styles from '../../Search/SearchWidget/SearchWidget.module.css';

interface SearchButtonProps {
  onClick: () => void;
  label?: string;
}
const SearchButton = ({ onClick, label }: SearchButtonProps) => {
  return (
    <li className={`cursor-pointer`}>
      <button
        className={`group flex items-center`}
        // This onMouseDown prevents the onBlur of the search input element.
        // Otherwise the search input would not be closed when clicking on the search button.
        onMouseDown={(event) => {
          event.preventDefault();
        }}
        onClick={(event) => {
          onClick();
        }}
      >
        <div
          id={styles['nav-item']}
          className={`group block h-[2.813rem] w-[2.813rem] rounded-full bg-primary bg-[length:28px] bg-center bg-no-repeat lg:flex lg:h-[4.25rem] lg:w-[4.25rem] lg:bg-[length:36px] lg:hover:bg-secondary ${styles.searchIcon}`}
        >
          <span className="sr-only">
            {label || <FormattedMessage defaultMessage={`Suche`} id="qi41lO" />}
          </span>
        </div>
        {label && (
          <p
            className={`ml-4 text-base-m font-bold text-menu lg:hidden lg:text-base-d ${
              label ? 'group-hover:block' : 'hidden'
            }`}
          >
            {label}
          </p>
        )}
      </button>
    </li>
  );
};

export default SearchButton;
