import { useRouter } from 'next/router';
import Link from 'next/link';
import styles from './NavItem.module.css';
import { DrupalMenuLinkContent } from 'next-drupal';
import { useCartContext } from 'lib/cart-context';

// TODO define correct Type
interface MenuLinkContent extends DrupalMenuLinkContent {
  options: {
    attributes: any;
  };
}

interface NavItemProps {
  link: MenuLinkContent;
  showLabelsOnHover?: boolean;
  className?: string;
  onClick?: any;
  cartFlag?: boolean;
}

export default function NavItem({
  link,
  showLabelsOnHover = true,
  className,
  onClick,
}: NavItemProps) {
  const { asPath, locale } = useRouter();
  const localizedPath = `/${locale + asPath}`;
  const { state } = useCartContext();

  // Default cart indicator value
  let cartIndicator = false;

  // We only select the first item because JSON API delivers multiple not correct
  const icon = link?.options?.attributes?.class
    ? link.options.attributes.class[0]
    : '';
  const target = link?.options?.attributes?.target
    ? link.options.attributes.target[0]
    : '';

  // Set boolean value to determine the cart indicator
  if (
    link?.options?.attributes?.class[0] === 'nav-item-warenkorb-negativ' &&
    state?.cartFlag
  ) {
    cartIndicator = true;
  }

  return (
    <li className={className}>
      <Link href={link?.url ?? ''} passHref target={target} onClick={onClick}>
        <div className={`group flex items-center`}>
          <div
            id={styles['nav-item']}
            className={`relative rounded-full bg-primary hover:bg-secondary lg:h-auto`}
          >
            {cartIndicator && (
              <span
                className={`absolute top-[-5px] inline-block h-4 w-4 rounded-full border bg-primary lg:top-[-10px] lg:h-6 lg:w-6`}
              ></span>
            )}
            <div
              className={`block h-[2.813rem] w-[2.813rem] bg-[length:28px] bg-center bg-no-repeat lg:flex lg:h-[4.25rem] lg:w-[4.25rem] lg:bg-[length:36px] ${
                localizedPath === link.url
                  ? 'opacity-50 lg:rounded-full lg:border lg:border-primary lg:opacity-100'
                  : ''
              } ${styles[icon]}`}
            ></div>
          </div>
          <p
            className={`ml-4 text-base-m font-bold text-menu lg:hidden lg:text-base-d ${
              showLabelsOnHover ? 'group-hover:block' : 'hidden'
            } 
          `}
          >
            {link.title}
          </p>
        </div>
      </Link>
    </li>
  );
}
