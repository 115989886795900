import Logo from 'components/blocks/Logo';
import MainMenu from 'components/blocks/MainMenu';
import SearchWidget from 'components/Search/SearchWidget';
import { useState } from 'react';
import SecondaryMenu from '../SecondaryMenu';

function handleSearchButtonClick(
  setSearchIsOpen,
  searchIsOpen,
  setAutocompleteIsOpen
) {
  setSearchIsOpen(!searchIsOpen);
  if (searchIsOpen) {
    setAutocompleteIsOpen(false);
  }
}

export default function Header({ menus }) {
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [autocompleteIsOpen, setAutocompleteIsOpen] = useState(false);
  return (
    <header>
      <SearchWidget
        searchIsOpen={searchIsOpen}
        setSearchIsOpen={setSearchIsOpen}
        setAutocompleteIsOpen={setAutocompleteIsOpen}
        autocompleteIsOpen={autocompleteIsOpen}
      />
      <MainMenu
        menu={menus.main}
        onSearchButtonClick={() =>
          handleSearchButtonClick(
            setSearchIsOpen,
            searchIsOpen,
            setAutocompleteIsOpen
          )
        }
      />
      {/*<LanguageSwitcher />*/}
      <SecondaryMenu
        menu={menus.stickyShop}
        onSearchButtonClick={() =>
          handleSearchButtonClick(
            setSearchIsOpen,
            searchIsOpen,
            setAutocompleteIsOpen
          )
        }
      />
      <Logo />
    </header>
  );
}
