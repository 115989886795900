import { drupal } from './drupal';
import { DrupalView } from 'next-drupal';

export function formatDate(input: string): string {
  const date = new Date(input);
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
}

export function formatDateShort(input: string): string {
  const date = new Date(input);
  return date.toLocaleDateString('de-CH', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}

export function absoluteUrl(input: string) {
  return `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}${input}`;
}

export const isActiveLink = (
  href: string,
  currentPathname: string
): boolean => {
  if (href === '/') {
    return href === currentPathname;
  }

  return currentPathname.startsWith(href);
};

export function isPublished(publishOn: string, unpublishOn: string) {
  const now = Date.now();

  if (publishOn) {
    const publishDate = Date.parse(publishOn);

    if (publishDate > now) {
      return false;
    }
  }

  if (unpublishOn) {
    const unpublishDate = Date.parse(unpublishOn);

    if (unpublishDate <= now) {
      return false;
    }
  }

  return true;
}
