import LogoSVG from 'public/images/logo-sauna.svg';
import Link from 'next/link';

export default function Logo() {
  return (
    <div className="absolute top-0 right-10 z-30">
      <Link href="/" passHref>
        <LogoSVG className="h-28 text-primary lg:h-44" />
      </Link>
    </div>
  );
}
