import { JsonApiParams } from 'next-drupal';
import { useRouter } from 'next/router';
import { useInfiniteQuery } from '@tanstack/react-query';

interface SearchContentParams extends JsonApiParams {
  page?: number;
}

interface InstantSearchContentParams extends JsonApiParams {
  page?: number;
  query?: string;
}

const NUMBER_OF_RESULTS_PER_PAGE = 6;
const NUMBER_OF_RESULTS_PER_INSTANT_PAGE = 5;

async function fetchContentSearch(params: SearchContentParams) {
  const response = await fetch('/api/search/content', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      page: params.page,
      params: {
        fields: {
          'node--page':
            'id,title,field_teaser_text,field_teaser_image,field_theme,created,path',
          'node--news':
            'id,title,field_teaser_text,field_teaser_image,field_theme,created,path',
        },
        include: 'field_teaser_image,field_teaser_image.field_media_image',
        filter: {
          fulltext: params.q,
        },
        page: {
          limit: NUMBER_OF_RESULTS_PER_PAGE,
          offset: params.page * NUMBER_OF_RESULTS_PER_PAGE,
        },
      },
    }),
  });

  return response.json();
}

export function useContentSearch(params: SearchContentParams = { page: 0 }) {
  const router = useRouter();
  const query = router.query;

  const results = useInfiniteQuery(
    ['searchContent', router],
    ({ pageParam = params.page }) => {
      return fetchContentSearch({ ...router.query, page: pageParam });
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: 0,
      initialData: {
        pageParams: [params.page],
        pages: [],
      },
      enabled: Object.keys(query)?.length !== 0,
      getNextPageParam: (lastPage) => {
        return lastPage?.nextPage ?? undefined;
      },
    }
  );

  return { ...results, query };
}

async function fetchContentInstantSearch(params: InstantSearchContentParams) {
  const instantResponse = await fetch('/api/search/content', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      page: params.page,
      params: {
        fields: {
          'node--page':
            'id,title,field_teaser_text,field_teaser_image,field_theme,created,path',
          'node--news':
            'id,title,field_teaser_text,field_teaser_image,field_theme,created,path',
        },
        include: 'field_teaser_image,field_teaser_image.field_media_image',
        filter: {
          fulltext: params.query,
        },
        page: {
          limit: NUMBER_OF_RESULTS_PER_INSTANT_PAGE,
          offset: params.page * NUMBER_OF_RESULTS_PER_INSTANT_PAGE,
        },
      },
    }),
  });

  return instantResponse.json();
}

export function useContentInstantSearch(
  params: InstantSearchContentParams = { page: 0, query: '' }
) {
  const results = useInfiniteQuery(
    ['searchInstantContent', params.query],
    ({ pageParam = params.page }) => {
      return fetchContentInstantSearch({
        page: pageParam,
        query: params.query,
      });
    },
    {
      initialData: {
        pageParams: [params.page],
        pages: [],
      },
      enabled: params.query.length > 2,
      getNextPageParam: (lastPage) => {
        return lastPage?.nextPage ?? undefined;
      },
    }
  );

  return { ...results };
}
