import { JsonApiParams } from 'next-drupal';
import { useRouter } from 'next/router';
import { useInfiniteQuery } from '@tanstack/react-query';

interface SearchProductsParams extends JsonApiParams {
  page?: number;
}

interface InstantSearchProductsParams extends JsonApiParams {
  page?: number;
  query?: string;
}

const NUMBER_OF_RESULTS_PER_PAGE = 6;
const NUMBER_OF_RESULTS_PER_INSTANT_PAGE = 5;

async function fetchProductsSearch(params: SearchProductsParams) {
  const response = await fetch('/api/search/products', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      page: params.page,
      params: {
        fields: {
          'product-variation--voucher': 'id,title,field_image,created',
          'product-variation--ticket': 'id,title,field_image,created',
          'product-variation--retail-products': 'id,title,field_image,created',
          'product-variation--reservation': 'id,title,field_image,created',
          'product-variation--package': 'id,title,field_image,created',
        },
        include: 'field_image,field_image.field_media_image',
        filter: {
          fulltext: params.q,
        },
        page: {
          limit: NUMBER_OF_RESULTS_PER_PAGE,
          offset: params.page * NUMBER_OF_RESULTS_PER_PAGE,
        },
      },
    }),
  });

  return response.json();
}

export function useProductsSearch(params: SearchProductsParams = { page: 0 }) {
  const router = useRouter();
  const query = router.query;

  const results = useInfiniteQuery(
    ['searchProducts', router],
    ({ pageParam = params.page }) => {
      return fetchProductsSearch({ ...router.query, page: pageParam });
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: 0,
      initialData: {
        pageParams: [params.page],
        pages: [],
      },
      enabled: Object.keys(query)?.length !== 0,
      getNextPageParam: (lastPage) => {
        return lastPage?.nextPage ?? undefined;
      },
    }
  );

  return { ...results, query };
}

async function fetchProductsInstantSearch(params: InstantSearchProductsParams) {
  const instantResponse = await fetch('/api/search/products', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      page: params.page,
      params: {
        fields: {
          'product-variation--voucher': 'id,title,field_image,created',
          'product-variation--ticket': 'id,title,field_image,created',
          'product-variation--retail-products': 'id,title,field_image,created',
          'product-variation--reservation': 'id,title,field_image,created',
          'product-variation--package': 'id,title,field_image,created',
        },
        include: 'field_image,field_image.field_media_image',
        filter: {
          fulltext: params.query,
        },
        page: {
          limit: NUMBER_OF_RESULTS_PER_INSTANT_PAGE,
          offset: params.page * NUMBER_OF_RESULTS_PER_INSTANT_PAGE,
        },
      },
    }),
  });

  return instantResponse.json();
}

export function useProductsInstantSearch(
  params: InstantSearchProductsParams = { page: 0, query: '' }
) {
  const results = useInfiniteQuery(
    ['searchInstantProducts', params.query],
    ({ pageParam = params.page }) => {
      return fetchProductsInstantSearch({
        page: pageParam,
        query: params.query,
      });
    },
    {
      initialData: {
        pageParams: [params.page],
        pages: [],
      },
      enabled: params.query.length > 2,
      getNextPageParam: (lastPage) => {
        return lastPage?.nextPage ?? undefined;
      },
    }
  );

  return { ...results };
}
