'use client';

import { useState } from 'react';
import { motion } from 'framer-motion';
import NavItem from 'components/NavItem';
import SearchButton from 'components/Search/SearchButton/SearchButton';
import { FormattedMessage, useIntl } from 'react-intl';

export default function MainMenu({ menu, onSearchButtonClick }) {
  const intl = useIntl();
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuCollapsing = () => {
    setMenuOpen((prevState) => !prevState);
  };

  return (
    <div className={`fixed left-0 top-0 z-30`}>
      <motion.div
        animate={{ opacity: menuOpen ? 1 : 0 }}
        transition={{ ease: 'easeInOut', duration: 0.5 }}
        className={`lg:hidden ${
          menuOpen ? 'fixed' : 'hidden'
        } left-0 top-0 z-30 h-full w-full bg-secondary`}
      ></motion.div>
      <div className="z-30 mx-auto flex h-16 w-full max-w-screen-xl items-center px-4 lg:h-auto lg:px-10">
        <motion.nav
          animate={{ x: menuOpen ? 0 : -100, opacity: menuOpen ? 1 : 0 }}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
          className={`left-0 top-4 z-40 h-full px-4 py-10 lg:px-10 ${
            menuOpen ? 'fixed' : 'hidden'
          }`}
        >
          <ul
            className={`items-left mt-16 flex h-full flex-col gap-y-7 overflow-y-auto lg:mt-9 lg:gap-y-9`}
          >
            {menu?.map((link) => (
              <NavItem
                key={link.id}
                className="cursor-pointer"
                link={link}
                onClick={handleMenuCollapsing}
              />
            ))}

            <SearchButton
              onClick={onSearchButtonClick}
              label={intl.formatMessage({
                defaultMessage: `Suche`,
                id: 'qi41lO',
              })}
            />
          </ul>
        </motion.nav>
        <div className="z-40 flex w-[3.375rem] items-center justify-center pt-7 lg:w-[4.25rem] lg:pt-11">
          <button
            onClick={handleMenuCollapsing}
            className="flex items-center justify-center text-center"
          >
            <div className="relative inline-block h-4.5 w-8">
              <span
                className={`absolute left-0 top-0 block h-0.5 w-full origin-center bg-primary opacity-100 transition ${
                  menuOpen ? 'rotate-45' : ''
                }`}
              ></span>
              <span className="absolute left-0 top-2 block h-0.5 w-full opacity-100 transition"></span>
              <span
                className={`absolute left-0 top-4 block h-0.5 w-full origin-center bg-primary opacity-100 transition ${
                  menuOpen ? 'top-[0] -rotate-45' : ''
                }`}
              ></span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
